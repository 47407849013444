import React, { Component, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Pageloader from "./components/common/pageloader/Pageloader";

// admin side
const ADRequest = lazy(() => import("./components/adr/ADRequest"));

// common
const PageNotFound = lazy(() => import("./components/common/PageNotFound"));

function App() {

  return (
    <div className="App">
      <Suspense
        fallback={
          <div className='fullpage-loader'><Pageloader /></div>
        }>
        <Router>
          <Routes>

            {/* admin */}
            <Route exact path="/PageNotFound" element={<PageNotFound />} />
            <Route exact path="/:slugName" element={<ADRequest />} />
            <Route exact path="/" element={<PageNotFound />} />

          </Routes>
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
